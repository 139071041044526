<template>
	<div>

		<form enctype="multipart/form-data" class="field anUploadField">

			<!-- this part is hidden but must be there for the upload field to works -->
			<div class="row justify-content-center hidden-input" style="height:0;">
				<div>
					<!--<label for="imageUpload" class="form-label" style="height:0; overflow: hidden; position: absolute; margin-left: 10000px; margin-top: 10000px;">{{label}}</label>-->
					<input tabindex="-1" :aria-label="label" ref="fileInput" class="form-control" style="height:0; border-bottom: 0px; z-index:-1;" id="imageUpload" name="imageUpload" @change="handleFilesUpload($event)" @focus="isFocused = true" @blur="isFocused = false" type="file" multiple>
				</div>
			</div>
			<!-- // -->

			<div class="container">

				<div
				@drop="launchFileTransfert($event)"
				@dragover.prevent 
				class="dropzone"
				:class="{isLoading: isLoading, focus: isFocused, hasSuccess: uploadSuccessful === true,
				hasError: uploadSuccessful === false}"
				@click="$refs.fileInput.click()"
				@keyup.prevent.enter = "$refs.fileInput.click()"
				style="text-align: center;"
				tabindex="0"
				role="button"
				>

				<div class="dropzone-content">

					<div class="icon" v-if="uploadFiles.length === 0">
						<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
						<path d="M19.9,9.4C19.9,9.4,19.9,9.3,19.9,9.4c0-3.5-2.8-6.2-6.2-6.2c-2.4,0-4.4,1.4-5.5,3.5C7.7,6.2,7.1,5.9,6.4,5.9
						c-1.7,0-3,1.4-3,3c0,0,0,0.1,0,0.3c-1.7,0.6-2.9,2.2-2.9,4c0,1.1,0.4,2.2,1.3,3c0.8,0.8,1.9,1.3,3,1.3h5.9v3.3h3.8v-3.3h5
						c2.3,0,4.1-1.8,4.1-4.1C23.5,11.3,21.9,9.6,19.9,9.4z M19.4,16.3h-5v-2.5h2.5l-4.3-4.6l-4.4,4.6h2.5v2.5H4.8c-0.8,0-1.6-0.3-2.2-0.9
						C2,14.8,1.7,14,1.7,13.2c0-1.5,1-2.7,2.5-3l0.6-0.1L4.7,9.4L4.6,9c0-1,0.8-1.8,1.8-1.8c0.6,0,1.2,0.3,1.5,0.8l0.7,1L9,7.8
						c0.7-2,2.6-3.4,4.7-3.4c2.7,0,5,2.2,5,5l-0.1,1.2l0.7,0l0.1,0c1.6,0,2.9,1.3,2.9,2.9C22.3,15,21,16.3,19.4,16.3z" />
					</svg>

				</div>

				<div class="textscontainer">

					<p class="p1" v-if="uploadFiles.length === 0" @keydown.prevent.enter="$refs.fileInput.click()">{{label}}<span v-if="isRequired" class="req"  tabindex="-1">*</span>
					</p>

					<hr v-if="uploadFiles.length === 0" aria-hidden="true">

					<p class="p2" v-if="uploadFiles.length === 0" role="text">
						<span v-html="$t('inputLabel.upload.rules')"></span><br>
						{{$t('inputLabel.upload.allowedFilesList')}}
					</p>
				</div>
			</div>





			<div v-for="(fileObject, index) in filesStatus" :key="index" class="dropzone-duplicables">

				<div id="frame" class="img-square-50px">
					<div id="coverPdf" class="" @click.stop="displayPdf(fileObject)"></div>
					<img :src="fileObject.preloadImgSrc" class="img-square-50px">
				</div>

				<div class="statuscontainer">

					<button @click.stop="removeImg(fileObject)" class="btn_small">{{$t('inputLabel.upload.remove')}}</button>

					<div class="microspinner" v-show="fileObject.uploadStatus === 'waiting'"></div>

					<p id="statusMessages" role="text">
						<span v-html="$t('inputLabel.upload.state')"></span>:
						<span v-if="fileObject.uploadStatus === 'waiting'">{{$t('inputLabel.upload.waiting')}} </span>
						<span v-else-if="fileObject.uploadStatus === 'success'">{{$t('inputLabel.upload.uploaded')}}</span>
						<span v-else-if="fileObject.uploadStatus === 'error'">{{$t('inputLabel.upload.error')}}</span>
					</p>

					<div id="successMessages" v-if="messages.length > 0">
						<ul>
							<li class="message" v-for="(msg, index) in messages" :key="index">{{ msg }}</li>
						</ul>
					</div>

					<div id="errorMessages" v-if="fileObject.errorMessage && fileObject.errorMessage.length > 0">
						<ul>
							<li class="message" v-for="(err, index) in fileObject.errorMessage" :key="index">{{ err }}</li>
						</ul>
					</div>

				</div>

			</div>

		</div>
	</div>

	<div class="restrictionupload" role="text" tabindex="0">{{$t('inputLabel.upload.onlyImages')}}<br>{{$t('inputLabel.upload.maxSize')}}</div>

</form>



</div>
</template>

<script>

	import config from "../config";
	import CustomLog from "../mixins/CustomLog";
	import APICalls from "../mixins/APICalls";

	export default {
		name: "Upload",
		mixins: [
			APICalls,
			CustomLog,
			],
		props: {
			label: {
				type: String,
				required: true,
			},
			fieldname: {
				type: String,
				required: true,
			},
			postUploadUrlProp: {
				type: String,
				required: true,
			},
			isRequired : {
				type: Boolean,
				required: false,
			default: false,
			},
			idParticipationOwner : {
				// type: String,
				required: false,
			},
			typeOfUpload : {
				type: String,
				required: true,
				validator : function(value){
					return ["dropzone1","dropzone2","ticket","logo","other"].includes(value);
				}
			},
		},

		data() {
			return {
				uploadFiles: [],
				filesStatus: [],
				messages: [],
				config: config,
				isLoading: false,
				isFocused: false,
				uploadSuccessful: null,
			}
		},

		methods: {

			handleFilesUpload: function(event, drop = false) {
				this.resetMessages();
				if(!drop) {
					this.uploadFiles = Array.from(event.target.files)
				}else{
					this.uploadFiles = Array.from(event)
				}
				this.filesStatus = []
				this.uploadFiles.forEach(file => {
					this.filesStatus.push({
						file: file,
						preloadImgSrc: URL.createObjectURL(file),
						uploadSuccess: false,
						uploadStatus: 'waiting'
					})
				})
				this.sendUploadedFiles();
			},

			resetMessages: function() {
				this.messages = [];
			},



			sendPostRequest(){
				/* CONFIG */
				let config = {
					headers: {
						'Accept' : 'application/json',
						'Content-Type': 'multipart/form-data'
					}
				}
				/* FORM */
				let form = new FormData();
				this.resetMessages();
				this.uploadFiles.forEach((file) => {
					form.append('files[]', file)
				});
				form.append('type', this.typeOfUpload);
				form.append('idLeadManagement', this.config.idlead);
				form.append('fingerprint', this.$store.getters.getFingerprint);
				if(this.idParticipationOwner){ form.append('idParticipationOwner', this.idParticipationOwner); }
				/* REQUEST */
				this.axios.post(this.postUploadUrlProp, form, config)
				.then(({data}) => {
					this.postRequestDidWell(data);
				})
				.catch(err => {
					this.postRequestDidWrong(err);
				});
			},

			postRequestDidWell(data){
				data.forEach(serverFileStatus => {
					return this.filesStatus
					.find(localStatus => serverFileStatus.name === localStatus.file.name)
					.uploadStatus = 'success';
				});
				this.messages.push(this.$t('inputLabel.upload.uploadsDone'));
				this.sendCheckSignal(true);
				this.uploadSuccessful = true;
				this.displayOrHidePDF(this.filesStatus[0].file.name);
			},

			postRequestDidWrong(err){
				this.filesStatus.forEach((obj, index) => {
					obj.uploadStatus = 'error'
					obj.errorMessage = ''
					if(err.response.status === 503){
						this.setNotLoading();
						this.$emit('maintenanceDetected');
					}else if(err.response.status === 422) {
						obj.errorMessage = err.response.data.errors[`files.${index}`];
					}else{
						obj.errorMessage = [this.$t('inputLabel.upload.generalError')]
					}
				});
				this.uploadSuccessful = false;
				this.sendCheckSignal(false);
			},



			sendUploadedFiles: function() {
				this.log("sendUploadedFiles", 'function');
				/* Must send a fingerprint with the file */
				/* Problem: some adblocker blocks the fingerprint from the JS and our store is empty */
				/* So, if it's the case, I must get it from ActionAPI */
				let storedFingerPrintShouldBeNonEmpty = this.$store.getters.getFingerprint;
				if(storedFingerPrintShouldBeNonEmpty){
					this.log("Fingerprint was found in store", 'info');
					this.sendPostRequest();
					this.isLoading = false;
				}else{
					this.log("Fingerprint was NOT found in store", 'info');
					this.askActionApiForFingerprint();
				}				
			},

			askActionApiForFingerprint(){
				this.log("Fetching fingerprint from Action API", 'low');
				this.getFromAxios(this.callConfigs.getFingerprint);				
			},

			whatIfIGotTheFingerPrint(fingerprint){
				this.log("Storing the fingerprint", 'low');
				this.$store.commit("SET_FINGERPRINT", fingerprint);
				this.sendPostRequest();
				this.isLoading = false;
			},
			whatIfIDontGetTheFingerprint(){
				this.log("Fingerprint not found", 'alert');
				this.isLoading = false;
			},

			displayOrHidePDF(filename) {
				/* If it's a PDF, shows a PDF */
				let el = document.getElementById('coverPdf');
				if(this.identifyAsPdf(filename)){
					el.classList.add('visible');
				}else{
					el.classList.remove('visible');
				}
			}, 

			identifyAsPdf(filename) {
				return (filename.slice(-3) === "pdf");
			},

			displayPdf(file){
				window.open(file.preloadImgSrc, '_blank').focus();
			},

			removeImg(fileObject) {
				this.filesStatus = this.filesStatus.filter(obj => obj.file.name !== fileObject.file.name)
				this.uploadFiles = this.uploadFiles.filter(file => file.name !== fileObject.file.name)

				if(this.uploadFiles.length > 0) {
					this.sendUploadedFiles();
				}
				this.uploadSuccessful = null;
				this.sendCheckSignal(false);
			},

			launchFileTransfert(event) {
				event.preventDefault();
				event.stopPropagation();
				this.isLoading = true;
				let dt = event.dataTransfer
				let files = dt.files
				this.handleFilesUpload(files, true)
			},

			sendCheckSignal(bool){
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},


		},

	}
</script>

<style scoped>
</style>
