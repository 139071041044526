<template>
	<div class="aTextArea field">
		<label  :for="fieldname"><span v-html="labelSpaced"></span>&nbsp;<span v-if="isRequired" class="req" tabindex="-1">*</span></label>
		<div class="inputwrapper">

			<textarea 
			:name="fieldname" 
			:id="fieldname"
			v-model="modelValueComputed"
			:placeholder="placeholder"
			:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
			tabindex="0"
			></textarea>

		</div>
		<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
		<div class="helper" role="textbox" :aria-label="'More info for ' + fieldname + ' field'">{{helper}}</div>
	</div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
	import { debounce } from "lodash";
	import FormFields from "../mixins/FormFields";
	import config from "../config";
	import CustomLog from "../mixins/CustomLog";

	export default {
		name: "TextArea",

		emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

		mixins: [
			CustomLog,
			FormFields,
			],

		props: {
			inputType:{
				type: String,
				validator : function(value){
					return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
				}
			},
			label : {
				type: String,
				required : true
			},
			isDisabled : {
				type: Boolean,
				required: false,
			default: false
			},
			acceptedValues: {
				type: Array, 
				required: false
			},
			valueHasChanged: {
				type: String,
				required: false
			},
		},

		data(){
			return {
				errorMessage : "",
				config: config,
			}
		},

		computed: {
			modelValueComputed: {
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.validateMyselfDebounced();
					this.$emit('update:modelValue', newVal);
				}
			}
		},

		methods : {


			validateMyself() {
				this.setLoading();
				this.removeSuccessOrError();
				this.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`, 'low');
        /* CUSTOM VALIDATION */

				/* To be valid, the filed must contain at least 1 caracters */

				if(this.modelValueComputed.length >= 1){
					this.log("Value accepted", 'low');
					this.giveSuccess();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
				}else{
					this.log("Value rejected", 'low');
					this.giveError();
					this.errorMessage = this.$t('inputsTexts.textArea.error_wrongValue');
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				}

				this.setNotLoading();
			},

		},

		created() {
			this.validateMyselfDebounced = debounce(this.validateMyself, 500);
    // run validation immediately when the value is not null or empty
			if(this.modelValueComputed) {
				this.validateMyselfDebounced();
			}
		},

		watch: {
			modelValue(){
				this.validateMyselfDebounced();
			},
		},

	}

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
