<template>

	<div class="field" :class="[{'checkbox': specialStyle!=='default'}, specialStyle]">

		<input type="checkbox" 
		:id="fieldname" 
		:name="fieldname" 
		v-model="checked" 
		:true-value="1"
		:false-value="0"
		@focus="isFocused = true"
		@blur="isFocused = false"
		tabindex="0"
		role="checkbox"
		aria-labelledby="checkbox-label"
		>
		<span class="checkmark" :class="{focus: isFocused}"></span>

		<label :for="fieldname" :class="{'hasSuccess': checked}" class="inlineblock"><slot name="msg"></slot>&nbsp;<span v-if="isRequired" class="req" tabindex="-1">*</span></label>

		<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
		<div class="helper" role="textbox" :aria-label="'More info for ' + fieldname + ' field'">{{helper}}</div>

	</div>

</template>

<!-- ================================================================================== -->

<script>
	import FormFields from "../mixins/FormFields";
	export default {
		name:"customCheckbox",

		mixins: [FormFields],
		
		props: {
			specialStyle: {
				type: String,
				required: true,
				validator : function(value){
					return ["default", "boldborder", "fullbg", "cross", "vcheck"].includes(value);
				}
			},
		},

		data() {
			return {
				isFocused: false,
			}
		},
		computed: {
			checked: {
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.$emit('update:modelValue', newVal);
					this.$emit('runCheck', {fieldname: this.fieldname, valid: Boolean(newVal)});
				}
			}
		},
		
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped></style>
