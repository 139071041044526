<template>
	<span tabindex="-1" class="skipLinksWrapper" :class="{hiddenField : isSkipLinkHidden}">
		<a 
		href="#section" 
		role="button" 
		class="skipLinks" 
		:class="{hiddenField : isSkipLinkHidden}" 
		@focus="showSkipLink(true)" 
		@blur="showSkipLink(false)"
		>{{$t("pageLitteracy.skipToMainContent")}}</a>
	</span>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"SkipLink",

		data(){
			return {
				skipLinkIsVisible: false,
			}
		},

		computed: {

			isSkipLinkHidden(){
				return !this.skipLinkIsVisible;
			},

		},

		methods: {

			showSkipLink(bool){
				console.log("Switching skiplink to " + bool);
				this.skipLinkIsVisible = bool;
			},

		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
