<template>
	<div class="page-form">


		<div class="titleContainer" v-show="title">
			<div class="iconContainer">

				<icons-svg v-show="$store.currentPage === 'form1'" icon="flat_form" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'form2'" icon="flat_ticket" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'confirmationCoupon'" icon="flat_confirm" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'instructions'" icon="flat_info" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'gift'" icon="flat_gift" class="iconbox"></icons-svg>
				<icons-svg v-show="['wrongcode2', 'wrongcode', 'participated', 'maintenance', 'maxReached', 'beforeStart', 'actionEnded'].includes($store.currentPage)" icon="cadenas" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'confirmRegistration'" icon="flat_store" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'printcoupon'" icon="flat_coupon" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'loading'" icon="flat_time" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'summary'" icon="flat_form" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'registration'" icon="flat_registration" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'survey'" icon="flat_registration" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'confirmation'" icon="flat_confirm" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'landing'" icon="flat_question" class="iconbox"></icons-svg>
				<icons-svg v-show="$store.currentPage === 'catalog'" icon="flat_question" class="iconbox"></icons-svg>
			</div>

			<div class="titletext">
				<h1 v-if="title">
					{{title}}
				</h1>
			</div>
		</div>


		<div class="pageSubtitle">{{subtitle}}</div>

		<slot name="content"></slot>
		<slot name="nav"></slot>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	import IconsSvg from '../components/IconsSvg';

	export default {
		name:"PageForm",
		components: {
			IconsSvg
		},
		props: {
			title: {
				type: String,
				required: false,
			},
			subtitle: {
				type: String,
				required: false,
			},
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
