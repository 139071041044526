export default {


    data () {
        return {
            isThereMessage: false,
            messageContent: [],
            finalMessageContent: null,
            messageTitle: '',
            messageType: "alert",
            errorRecognized: false,
        }
    },


    methods: {
        /* ! This is a double! It exists in wowilogic.js too! */
        displayModaleMessages(title, arrayOfMessages, csq=null, mode='info') {
            this.log("Displaying message by toaster", 'low');

            this.isThereMessage = true;
            this.messageTitle = title;

            if(!Array.isArray(arrayOfMessages)){
                this.log("arrayOfMessages was not an array", 'alert');
                arrayOfMessages = [arrayOfMessages];
            }

            this.messageContent = arrayOfMessages;
            this.finalMessageContent = csq;
            this.messageType = mode;
        },
        

        hideMessage() {
            this.isThereMessage = false;
        },

        displayUndefinedError(){
            this.log("displayUndefinedError()", 'function');
            this.displayModaleMessages(this.$t('errors.undefinedError'), [this.$t('errors.undefinedErrorPleaseRetry')], this.$t('errors.noDescription'), "alert");
        },

    /* used to retrieve an unknown number of value of table constructed like : [0: ["lorem"], 1: ["ipsum"]] */
        extractErrorsStrings(listErrors){
            let bigArray = [];
            listErrors.forEach(obj => {
                let arrayOfValues = Object.values(obj);
                this.log(arrayOfValues);
                bigArray = [...bigArray, ...arrayOfValues];
            });
            return bigArray;
        },

        /* * not used ? */
        transmitErrorFromApi(payload){
            this.log("Just a small conversion", 'low');
            this.iGotThisErrorFromActionApi(payload.error, payload.formMessage);
        },


        // an error has been returned by ACTION_API. Now, I should display a modale with the errors descriptions
        iGotThisErrorFromActionApi(error, formMessage=null){
            this.log("Begin iGotThisErrorFromActionApi()", 'function');
            this.log("I received these data");
            // this.log(error);
            this.log(formMessage);
            this.log(error.response.data);

            let arrayOfErrorMessages = [];

            /* Default values */
            let errorTitle = this.$t('errors.unmanaged');
            let modaleType = "alert";


            // ANY RESPONSE FROM NETWORK NOT FROM ACTION-API
            if(error.response.status >= 500 && error.response.status < 600){
                // special case : at least in prod, we must hide technical details
                this.log(`This is a server error number ${error.response.status}`);
                errorTitle = `Err ${error.response.status} — ${error.response.statusText}`;
                this.log(errorTitle);
                this.log(error.response.data.message);
                arrayOfErrorMessages.push(this.$t('errors.error500'));
                formMessage = this.$t('errors.formUnavailable');
                this.errorRecognized = true;
            }

            // ACTION-API ANSWERS
            // just not found
            else if(error.response.status === 404){
                this.log("Erreur 404", 'red');
                if (error.response.data.code === "COUPON_PRINTED") {
                    this.log("User has already 'printed' his coupon", 'red');
                    errorTitle = this.$t('errors.oupsy');
                    arrayOfErrorMessages.push(error.response.data.errors.PRINTED[0]);  
                    this.errorRecognized = true;
                }else if (error.response.data.code === "FILE_NOT_FOUND") {
                    this.log("There is no coupon file !!!", 'alert');
                    errorTitle = this.$t('errors.e404');
                    arrayOfErrorMessages.push(error.response.data.errors.FILE[0]);  
                    this.errorRecognized = true;
                }else{
                    errorTitle = `Err ${error.response.status} — ${error.response.statusText}`;
                    arrayOfErrorMessages.push(error.response.data.message);
                    this.errorRecognized = true;
                }
            }

            // Error with the data transmitted
            else if(error.response.status == 422){
                this.log(`This is a servor error number ${error.response.status}`);

                if(error.response.data.code === "PARTICIPATED"){

                    this.log("User has already participated with these identifiers");
                    errorTitle = error.response.data.message;
                    arrayOfErrorMessages.push(error.response.data.notifications);    

                }
                else{
                    this.log("Error different from 'PARTICIPATED' and different from 'COUPON_PRINTED'");

                    try{
                        if(error.response.data.message && error.response.data.message != ""){
                            this.log("case 1");
                            errorTitle = error.response.data.message; // "the given data was invalid"
                        }else{
                            this.log("case 2");
                            errorTitle = "Probably an RPG error";
                        }
                        if(error.response.data.errors){
                            this.log("case 3");
                            // WIP I must wait for another RPG error to happend to test this
                            // this.log(Object.keys("").length === 0);

                            let temp =  Object.values(error.response.data.errors);
                            arrayOfErrorMessages = this.extractErrorsStrings(temp);
                        }else{
                            this.log("case 4");
                            arrayOfErrorMessages = ['No code transmitted'];
                        }

                    }catch(e){
                        this.log("Error inside errorsManager :/", 'alert');
                        console.log(e);
                    }
                }

                this.errorRecognized = true;
            }else{
                this.log("This is not a 404, 422 or 500 to 600 ERROR");
                this.errorRecognized = false;
            }

        // All analyse done, now, we display the message
            if(this.errorRecognized){
                this.log(`Displaying: ${errorTitle}: ${[...arrayOfErrorMessages]}`);
                this.displayModaleMessages(errorTitle, [...arrayOfErrorMessages], formMessage, modaleType);

                // ? why not this one? this.turnThisModaleVisibility('error', true);
            }else{
                this.displayUndefinedError();
            }

        },
    },
}

