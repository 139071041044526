<template>
	<div>



		<!-- ++++ FLAT INFO ++++ -->
		<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_info'">
		<polygon class="baseFill" points="7.9,8.2 7.9,10.3 10,10.4 10,20.5 6.7,20.5 6.7,23.2 17,23.2 17,20.5 13.9,20.5 13.9,7.4 	"/>
		<circle class="baseFill" cx="12.7" cy="3.5" r="2.6"/>
	</svg>

	<!-- ++++ FLAT CONFIRM ++++ -->
	<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_confirm'">
	<path id="Tick-V" class="baseFill" d="M4.2,10.1c0.5,0,4.8,4.7,5.4,4.8c0.5,0,10.1-10,10.7-10.1c0.5,0,2.2,1.9,2.2,2.5
	c0,0.5-12.2,12.8-12.8,12.8c-0.6,0-7.6-7-7.7-7.5C2.1,12,3.7,10.1,4.2,10.1z" />
</svg>

<!-- ++++ FLAT TICKET ++++ -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_ticket'">
<path id="Ticket" class="baseFill" d="M20.3,2.2c0,0-0.2-0.6-0.6-0.6c-0.5,0-1.8,1.8-2.2,1.7c-0.5,0-2-1.7-2.5-1.7
c-0.4,0-2.1,1.7-2.6,1.7c-0.4,0-2.2-1.7-2.6-1.7c-0.5,0-2,1.7-2.4,1.7c-0.4,0-1.7-1.7-2.2-1.7c-0.3,0-0.7,0.6-0.7,0.6l0,19.4
c0,0,0.2,0.6,0.7,0.7c0.4,0,1.7-1.8,2.2-1.8c0.5,0,1.7,1.8,2.4,1.8c0.7,0,2.1-1.8,2.6-1.9c0.5,0,2.3,1.9,2.8,1.9c0.5,0,2.4-2,2.7-2
c0.4,0,1.4,2,1.7,2c0.4,0,0.6-0.6,0.6-0.6L20.3,2.2z M17,16.3l-9.3,0c0,0-0.6,0-0.6-0.5c0-0.6,0.6-0.6,0.6-0.6H17c0,0,0.6,0,0.6,0.5
C17.6,16.3,17,16.3,17,16.3z M17,12.5l-9.3,0c0,0-0.6,0-0.6-0.5c0-0.6,0.6-0.6,0.6-0.6H17c0,0,0.6,0,0.6,0.5
C17.6,12.6,17,12.5,17,12.5z M17,8.6l-9.3,0c0,0-0.6,0-0.6-0.5c0-0.6,0.6-0.6,0.6-0.6H17c0,0,0.6,0,0.6,0.5C17.6,8.6,17,8.6,17,8.6z
" />
</svg>


<!-- ++++ FLAT GIFT ++++ -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_gift'">
<path class="baseFill" d="M2.4,5.3l-0.6,5.4h1v10.8c0,0.4,0.4,0.8,0.9,0.8h15.7c0.5,0,0.9-0.4,0.9-0.9V10.7h1.1l-0.6-5.4H2.4z"/>
<path class="giftWrap" d="M18.9,2.7c-0.3-0.5-0.7-0.9-1.2-1.2c-0.2,0-0.7-0.1-0.9-0.1c-0.4,0.1-3.1,1.6-3.1,1.6s0.4-0.7,0.8-1.1
s1.9-1,1.9-1c-0.5-0.2-1.4-0.5-1.5-0.5c0,0-1.3,0.6-1.8,1c-0.5,0.4-1.7,1.8-1.7,1.8c0-0.4-0.1-0.9-0.2-1.2C11,1.4,10,0.4,10,0.4
L8.8,0.9c0,0,0.7,0.5,0.9,1.1c0.1,0.2,0.1,0.4,0.1,0.5C9.2,2,8.5,1.3,7.5,1.1C6.8,0.9,6.2,1.4,6,1.6s-0.4,0.7-0.4,1
c0,0.3,0.3,1.4,0.5,1.7c0,0,0.3,0.4,1.5,0.9C8.9,5.6,10,5.5,10,5.5c0.1,0.1,0,16.8,0,16.8H13c0,0-0.1-16.7,0-16.8s5.3-1.5,5.9-2.3
C19,3,19,2.8,18.9,2.7z"/>
</svg>

<!-- ++++ FLAT STORE ++++ -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_store'">
<path id="Basket" class="baseFill" d="M1.3,2.1c0,0,3.3-0.1,4,0.1
C5.7,2.3,6,2.5,6.1,2.6C6.3,3,6.2,4.5,6.2,4.5l15.9,0.1c0,0,0.4,0.2,0.5,0.4s0,0.9,0,0.9l-1.5,7.3l-0.7,1.4H8.8L8.9,16H20
c0,0,0.2,0.8,0.2,1.2s-0.2,1-0.2,1s1.3,0.5,1.3,1.9c0,1.2-0.8,2-1.9,2c-1.5,0-2.5-0.9-2.3-2.2c0.1-1.2,0.7-1.5,0.7-1.5H9.2
c0,0,0.9,0.6,0.9,1.7c0,1-1,2.2-2.1,2.2c-1.4,0-2.2-0.7-2.3-2s1.1-1.9,1.1-1.9l-0.5-3.8l-2-10h-3c0,0-0.9,0.1-0.9-1.2
C0.3,2.1,1.3,2.1,1.3,2.1z" />
</svg>

<!-- ++++ FLAT FORM ++++ -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_form'">
<path id="_x3C_Path_x3E__angle" class="baseFill" d="M16,6.1h4.5L15.3,1v4.3c0,0.2,0,0.4,0.2,0.6C15.6,6,15.8,6.1,16,6.1z"/>
<path id="_x3C_Path_x3E__page" class="baseFill" d="M16,6.9c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.4-1.1V0.7H5.3
c-0.5,0-1,0.2-1.3,0.5S3.6,1.9,3.6,2.3L3.5,21.6c-0.1,0.4,0.1,0.9,0.4,1.2c0.3,0.3,0.8,0.5,1.2,0.4l14,0.1c0.4,0,0.8-0.2,1.1-0.6
c0.2-0.3,0.3-0.6,0.3-0.9l0.1-14.9H16z"/>
<path class="whiteFill" d="M16.2,15.2H9.4C9.2,15.2,9,15,9,14.8s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.4,15.2,16.2,15.2z"/>
<path class="whiteFill" d="M16.2,12.4H9.4C9.2,12.4,9,12.2,9,12s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.4,12.4,16.2,12.4z"/>
<path class="whiteFill" d="M16.2,17.9H9.4c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.4,17.9,16.2,17.9z"/>
<circle class="whiteFill" cx="6.7" cy="12" r="0.4"/>
<circle class="whiteFill" cx="6.7" cy="14.8" r="0.4"/>
<circle class="whiteFill" cx="6.7" cy="17.5" r="0.4"/>
</svg>

<!-- ++++ FLAT REGISTRATION ++++ -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_registration'">
<path class="baseFill" d="M11.7,2.2c3.1,0,5.2,2.7,5.2,5.1c0,2.4-1.9,5.1-5.1,5.2c-3.2,0.1-5.2-2.4-5.3-5S8.5,2.2,11.7,2.2z" />
<path class="baseFill" d="M20.6,22.5H2.5c0,0,0-3.1,0.2-3.7s0.6-2.5,1.6-3.5s2.6-1.5,3.7-1.5s2.4,0.6,3.6,0.7s2.4-0.7,3.7-0.7
s3,1,3.6,1.7s1.2,2.3,1.3,2.9S20.6,22.5,20.6,22.5z" />
</svg>

<!-- ++++ FLAT CASHBACK ++++ -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_cashback'">
<ellipse class="baseFill" cx="11.8" cy="11.5" rx="10.7" ry="10.6"/>
<path class="whiteFill" d="M7,12.4C7,12.7,7.3,13,7.6,13l0,0h1c0.1,0.4,0.4,0.9,0.7,1.3c0.6,0.7,1.6,1.2,2.7,1.2c0.7,0,1.5-0.1,2.1-0.6
c0.3-0.1,0.3-0.4,0.1-0.7c-0.1-0.3-0.4-0.3-0.7-0.1c-1,0.6-2.5,0.4-3.3-0.4c-0.1-0.1-0.3-0.4-0.4-0.6h1.5c0.3,0,0.6-0.3,0.6-0.6l0,0
c0-0.3-0.3-0.6-0.6-0.6l0,0L9.4,12c-0.1-0.3-0.1-0.7,0-1l2.7-0.3c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6l0,0L9.7,9.6
C9.8,9.3,10,9,10.1,8.9c0.7-1,2.2-1.3,3.1-0.6l0,0c0.3,0.1,0.7,0.1,0.9-0.1c0.1-0.3,0.1-0.6-0.1-0.7l0,0C12.5,6.2,10.4,6.5,9.2,8
l0,0C8.9,8.4,8.7,9,8.5,9.5L7.6,9.6C7.3,9.6,7,9.9,7,10.2c0,0.3,0.3,0.6,0.6,0.6l0,0h0.6c0,0.3,0,0.7,0,1l-0.6,0
C7.3,11.8,7,12.1,7,12.4z"/>
<path class="whiteFill" d="M9.1,19.6L9.1,19.6c0.4,0,0.6-0.2,0.6-0.5s-0.1-0.4-0.4-0.5c-2.3-0.7-4.3-2.7-4.9-5.2c-1.4-3.4,0.4-7.4,3.8-9
c2.1-1,4.4-1,6.5,0c2,0.9,3.5,2.5,3.9,4.6c0.9,2.7-0.2,5.6-2,7.7v-2c0-0.2-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v3.3l3.6-0.5
c0.2,0,0.5-0.2,0.4-0.5l0,0c0-0.2-0.2-0.5-0.5-0.4l0,0l-1.5,0.1c2-2.2,2.6-5.3,1.8-8.2c-0.6-2.3-2.3-4.1-4.5-5.2
c-2.3-1.1-5-1.1-7.2,0C4,5.4,2.1,9.7,3.5,13.8C4.3,16.5,6.4,18.7,9.1,19.6z"/>
</svg>






<!-- ++++ FLAT QUESTION ++++ -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'flat_question'">
<path class="baseFill" d="M5.4,5.8c0,0.5,1.4,2.1,2,2.1s1.9-2.3,4.5-2.4c2.1-0.1,3.2,0.8,3.2,2.3c0.1,1.8-3.5,2.9-4.5,4.2
s-0.7,3-0.4,3.3c0.2,0.2,3.7,0.3,3.9,0.1c0.2-0.3,0-1.2,0.6-1.9c0.7-0.7,3.9-2.5,4.5-4.3c0.4-1.2,0.1-2.3-0.2-3.3
c-0.4-1.1-2.3-4.1-6.5-4.1C8,1.7,5.4,5.3,5.4,5.8z" />
<path class="baseFill" d="M12,17.1c1.5,0,2.7,1.2,2.7,2.6c0,1.4-1.1,2.5-2.5,2.5c-1.9,0-2.6-1.4-2.6-2.4S10.2,17.2,12,17.1z" />
</svg>




<!-- ---- CADENAS ---- -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'cadenas'" aria-hidden="true" role="img" aria-label="lock icon">
<path class="baseFill" d="M20.4,9.9h-1.2l0-3.2c0-0.2,0-1.9-1.4-3.4c-1.3-1.3-3.2-2-5.9-2c0,0,0,0,0,0C9.3,1.4,7.3,2,6,3.4
C4.8,4.8,4.8,6.3,4.8,6.7v3.2H3.6c-0.6,0-1,0.5-1,1v10.3c0,0.6,0.5,1,1,1h16.8c0.6,0,1-0.5,1-1V11C21.4,10.4,21,9.9,20.4,9.9z
M13.4,19.3h-2.8l0.5-3.2c-0.5-0.3-0.8-0.8-0.8-1.4c0-0.9,0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7c0,0.6-0.3,1.1-0.8,1.4L13.4,19.3z
M16.7,9.9H7.3V6.7l0-0.1c0,0,0-0.8,0.6-1.5c0.7-0.8,2.1-1.2,4.1-1.2c0,0,0,0,0,0c4.6,0,4.8,2.5,4.8,2.8V9.9z"/>
</svg>


<!-- ---- INFO ---- -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'info'" aria-hidden="true" role="img" aria-label="info icon">
<path d="M16.9,19h-2.3V7.5c0-0.2-0.2-0.4-0.4-0.4H8.6c-0.2,0-0.4,0.2-0.4,0.4v3c0,0.2,0.2,0.4,0.4,0.4h0.9V19H7.1
c-0.2,0-0.4,0.2-0.4,0.4v3.2c0,0.2,0.2,0.4,0.4,0.4h9.8c0.2,0,0.4-0.2,0.4-0.4v-3.2C17.3,19.2,17.1,19,16.9,19z M16.5,22.2H7.5v-2.4
h2.4c0.2,0,0.4-0.2,0.4-0.4v-8.9c0-0.2-0.2-0.4-0.4-0.4H9V7.9h4.9v11.5c0,0.2,0.2,0.4,0.4,0.4h2.3L16.5,22.2z"/>
<path d="M9.5,6.2l2.3,0c0.1,0,0.1,0,0.2,0c0.6,0,1.2-0.2,1.7-0.6c1-1,1.1-2.6,0.3-3.8c-1.1-1.2-2.9-0.7-3.8,0.1
C9.7,2.4,9.5,3.1,9.5,3.9V6.2z M10.7,2.4c0.5-0.4,1.9-1.1,2.8-0.1c0.6,0.9,0.4,2-0.3,2.8c-0.4,0.3-0.9,0.4-1.3,0.4h-1.6l0-1.6
C10.2,3.3,10.4,2.8,10.7,2.4z"/>
</svg>

<!-- ---- REGISTRATION ---- -->
<svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'registration'" aria-hidden="true" role="img" aria-label="registration icon">
<path d="M9.9,13.2H6c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.4-0.7,1-0.7,1.6v1.2c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-1.2
c0-0.4,0.2-0.7,0.5-1C5.1,14,5.6,14,6,14h3.9c0.3,0,0.6,0.1,0.9,0.3c0.2,0.3,0.3,0.6,0.3,0.9v1.3c0,0.2,0.2,0.4,0.4,0.4
s0.4-0.2,0.4-0.4v-1.3c0-0.5-0.2-1-0.5-1.4C11,13.4,10.4,13.2,9.9,13.2z"/>
<path d="M20.3,16.1h-5.9c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h5.9c0.2,0,0.4-0.2,0.4-0.4S20.5,16.1,20.3,16.1z"/>
<path d="M20.3,12.2h-5.8c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h5.8c0.2,0,0.4-0.2,0.4-0.4S20.5,12.2,20.3,12.2z"/>
<path d="M20.3,8.4h-5.8c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h5.8c0.2,0,0.4-0.2,0.4-0.4S20.5,8.4,20.3,8.4z"/>
<path d="M23.4,18.5V5.6c0-0.5-0.3-1-0.6-1.4c-0.4-0.4-0.8-0.6-1.4-0.6L2.8,3.5h0C2.2,3.5,1.6,3.7,1.1,4C0.8,4.4,0.5,5,0.5,5.5
l0.1,13.1c0,0.5,0.2,1,0.5,1.5c0.4,0.4,1,0.6,1.6,0.6l18.7,0c0.1,0,0.1,0,0.2,0c0.5,0,1-0.2,1.4-0.6C23.3,19.6,23.4,19,23.4,18.5z
M22.4,19.6c-0.2,0.3-0.6,0.4-0.9,0.4H2.7c-0.4,0-0.7-0.1-1-0.4c-0.2-0.3-0.3-0.6-0.3-1L1.3,5.5c0-0.3,0.1-0.7,0.4-0.9
C2,4.4,2.4,4.3,2.8,4.3l18.6,0.1c0.3,0,0.6,0.2,0.8,0.4c0.2,0.2,0.4,0.5,0.4,0.9l0,12.9C22.7,18.9,22.6,19.3,22.4,19.6z"/>
<path d="M7.7,12.1c1.2,0,2.3-1,2.3-2.2c0-0.1,0-0.1,0-0.2C10.1,8.5,9.2,7.4,8,7.3c-0.1,0-0.2,0-0.3,0c-1.4,0-2.1,0.8-2.3,2.4
C5.4,11,6.4,12,7.7,12.1C7.7,12.1,7.7,12.1,7.7,12.1z M7.7,8.1C8.5,8,9.2,8.6,9.2,9.4c0,0.1,0,0.2,0,0.3c0.1,0.8-0.5,1.5-1.4,1.6
c-0.1,0-0.1,0-0.2,0c-0.8,0-1.5-0.7-1.5-1.5c0,0,0,0,0,0C6.3,8.6,6.7,8.1,7.7,8.1z"/>
</svg>

<!-- ---- STORE ---- -->
<svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'store'" aria-hidden="true" role="img" aria-label="store icon">
<path d="M21.7,8.7H19c0,0,0,0,0,0l-4.3-5.9c-0.4-0.5-1.2-0.6-1.7-0.2c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.1,0.6,0.2,0.8l3.4,4.5L8,8.7
l3.3-4.5c0.2-0.2,0.3-0.5,0.3-0.8c-0.1-0.3-0.2-0.6-0.5-0.8c-0.5-0.4-1.3-0.3-1.7,0.2L5,8.7c0,0,0,0,0,0H2.2c-0.4,0-0.8,0.1-1.1,0.4
c-0.3,0.3-0.5,0.8-0.5,1.3c-0.1,0.9,0.6,1.6,1.5,1.7c0,0,0.1,0,0.1,0h0.3L4,20.5c0,0.4,0.2,0.8,0.5,1c0.3,0.2,0.7,0.3,1.1,0.3h12.9
c0.3,0,0.7-0.1,1-0.3c0.2-0.3,0.4-0.6,0.5-0.9l1.6-8.5h0.1c0.9,0,1.6-0.7,1.6-1.6c0,0,0-0.1,0-0.1C23.3,9.5,22.7,8.8,21.7,8.7
C21.8,8.7,21.7,8.7,21.7,8.7z M17,8.7l-3.7-4.9c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.5-0.2,0.7,0l4,5.5L17,8.7
C17,8.7,17,8.7,17,8.7z M9.9,3.3c0.2-0.2,0.5-0.2,0.7-0.1c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3L7.1,8.7
c0,0,0,0,0,0H5.9L9.9,3.3z M21.7,11.3h-0.2h-0.5h-7.3c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4l0,0h7l-1.6,8.3
c-0.1,0.2-0.2,0.4-0.3,0.6C18.8,21,18.7,21,18.5,21H5.6c-0.2,0-0.5,0-0.7-0.1c-0.1-0.1-0.1-0.3-0.1-0.5l-1.5-8.4h7
c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4h-8c-0.5,0-0.8-0.4-0.8-0.8c0,0,0-0.1,0-0.1c0-0.3,0.1-0.5,0.2-0.7C1.8,9.5,2,9.5,2.2,9.5
h19.5c0.5,0,0.8,0.4,0.8,0.8c0,0,0,0.1,0,0.1c0.1,0.5-0.3,0.9-0.7,0.9C21.8,11.3,21.7,11.3,21.7,11.3z"/>
<path d="M8.5,13.9c-0.3-0.3-0.6-0.4-1-0.4c-0.6-0.1-1.2,0.4-1.3,1.1c0,0.1,0,0.2,0,0.2v3.4c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
l0,0l0-3.4C8.8,14.5,8.7,14.2,8.5,13.9z M8,14.8v3.4c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-3.4l0,0c0-0.1,0-0.2,0.1-0.3
c0.1-0.1,0.3-0.2,0.4-0.1c0.2,0,0.3,0,0.4,0.1C8,14.5,8,14.7,8,14.8z"/>
<path d="M13,13.9c-0.3-0.3-0.6-0.4-1-0.4c-0.6-0.1-1.2,0.4-1.3,1.1c0,0.1,0,0.2,0,0.2v3.4c0,0.7,0.6,1.3,1.3,1.3
c0.7,0,1.3-0.6,1.3-1.3l0-3.4C13.3,14.5,13.2,14.2,13,13.9z M12.5,14.8v3.4c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5l0,0v-3.4
l0,0c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.3-0.2,0.4-0.1c0.2,0,0.3,0,0.4,0.1C12.5,14.5,12.5,14.7,12.5,14.8L12.5,14.8z"/>
<path d="M15.1,18.2c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3l0-3.4c0-0.3-0.1-0.7-0.3-0.9c-0.5-0.5-1.3-0.6-1.8-0.2
c-0.3,0.3-0.5,0.7-0.5,1.1L15.1,18.2z M16,14.4c0.3-0.2,0.6-0.2,0.8,0c0.1,0.1,0.1,0.3,0.1,0.4v3.4c0,0.3-0.2,0.5-0.5,0.5
c-0.3,0-0.5-0.2-0.5-0.5v-3.4l0,0C15.9,14.6,15.9,14.5,16,14.4L16,14.4z"/>
<path d="M12,12c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.1-0.4,0-0.5c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0.2-0.1,0.4,0,0.5C11.8,11.9,11.9,12,12,12z
"/>
</svg>

<!-- ---- TICKET ---- -->
<svg class="icon" version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'ticket'" aria-hidden="true" role="img" aria-label="ticket icon">
<path d="M16.2,14.2H7.7c-0.2,0-0.5-0.2-0.5-0.4c0-0.2,0.2-0.4,0.5-0.4h8.5c0.2,0,0.5,0.2,0.5,0.4C16.7,14,16.4,14.2,16.2,14.2z"/>
<path d="M16.2,9.3H7.7c-0.2,0-0.5-0.2-0.5-0.4c0-0.2,0.2-0.4,0.5-0.4h8.5c0.2,0,0.5,0.2,0.5,0.4C16.7,9.1,16.4,9.3,16.2,9.3z"/>
<path d="M16.2,16.7H7.7c-0.2,0-0.5-0.2-0.5-0.4s0.2-0.4,0.5-0.4h8.5c0.2,0,0.5,0.2,0.5,0.4S16.4,16.7,16.2,16.7z"/>
<path d="M19,23.3l-0.4,0c-0.5,0-1.1-0.7-1.9-1.8c-0.2-0.2-0.4-0.6-0.6-0.7c-0.2,0.2-0.4,0.7-0.6,1c-0.6,1.1-0.9,1.5-1.3,1.5
c-0.3,0-0.6-0.3-1.5-1.4c-0.3-0.4-0.7-0.9-1-1.2c-0.2,0.2-0.5,0.8-0.7,1.1c-0.7,1.1-0.9,1.5-1.3,1.5c-0.3,0-0.5-0.3-1.4-1.4
C8,21.5,7.6,21,7.4,20.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,1-1,1.9-1.7,1.9c0,0,0,0,0,0l-0.4,0V0.8l0.4,0c0.6,0,1.2,0.7,2,1.9
c0.2,0.2,0.4,0.5,0.5,0.7C8,3.1,8.3,2.6,8.5,2.3c0.7-1.1,0.9-1.5,1.2-1.5c0.3,0,0.6,0.3,1.5,1.5c0.2,0.3,0.6,0.8,0.8,1
c0.2-0.2,0.5-0.7,0.7-1c0.8-1.3,1.1-1.6,1.4-1.6c0.3,0,0.6,0.3,1.4,1.5c0.2,0.3,0.5,0.8,0.7,1c0.1-0.2,0.2-0.4,0.4-0.6
c0.7-1.2,1.3-2,1.9-2l0.4,0l0,0.4c0,0.6,0,19.8,0,21.8L19,23.3z M11.7,19.9c0.3,0,0.6,0.3,1.6,1.5c0.3,0.3,0.7,0.8,0.9,1
c0.2-0.2,0.4-0.7,0.6-1c0.6-1.1,0.9-1.5,1.2-1.5c0.3,0,0.5,0.3,1.2,1.1c0.2,0.3,0.6,0.8,1,1.2c0-3.6,0-17.2,0-20.4
c-0.3,0.4-0.7,1-0.9,1.3c-0.5,0.7-0.7,1.1-1,1.1c-0.3,0-0.5-0.3-1.4-1.5c-0.2-0.3-0.6-0.8-0.8-1.1c-0.2,0.3-0.5,0.8-0.8,1.1
c-0.8,1.2-1,1.5-1.3,1.5c-0.3,0-0.5-0.2-1.4-1.5C10.4,2.4,10,1.9,9.8,1.6c-0.2,0.2-0.4,0.7-0.6,1c-0.7,1.2-1,1.5-1.3,1.6
c-0.3,0-0.6-0.3-1.2-1.1c-0.2-0.3-0.6-0.9-1-1.3v20.4c0.2-0.4,0.5-0.9,0.6-1.1c0.4-0.8,0.6-1.1,0.9-1.1c0.3,0,0.6,0.3,1.6,1.5
c0.3,0.3,0.6,0.8,0.8,1c0.2-0.2,0.4-0.7,0.6-1C11,20.3,11.3,19.9,11.7,19.9z M16.1,3.5L16.1,3.5L16.1,3.5z M14.3,1.5L14.3,1.5
L14.3,1.5z M9.9,1.5L9.9,1.5L9.9,1.5z"/>
<path d="M16.2,11.8H7.7c-0.2,0-0.5-0.2-0.5-0.4S7.5,11,7.7,11h8.5c0.2,0,0.5,0.2,0.5,0.4S16.4,11.8,16.2,11.8z"/>
</svg>

<!-- ---- FORM ---- -->
<svg class="icon" version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'form'" aria-hidden="true" role="img" aria-label="form icon">
<g id="Form">
	<path d="M16.2,15.2H9.4C9.2,15.2,9,15,9,14.8s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.4,15.2,16.2,15.2z"/>
	<path d="M16.3,12.4H9.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.5,12.4,16.3,12.4z"/>
	<path d="M16.3,17.9H9.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.5,17.9,16.3,17.9z"/>
	<circle cx="6.7" cy="12" r="0.3"/>
	<circle cx="6.7" cy="14.8" r="0.3"/>
	<circle cx="6.7" cy="17.5" r="0.3"/>
	<path d="M19.1,23.3l-14-0.1c-0.4,0.1-0.9-0.1-1.2-0.4c-0.3-0.3-0.5-0.8-0.4-1.2L3.6,2.3l0,0c0-0.4,0.1-0.8,0.4-1.1
	c0.3-0.3,0.8-0.5,1.3-0.5H15l5.6,5.5l-0.1,15.6c0,0.3-0.1,0.6-0.3,0.9C19.9,23.1,19.5,23.3,19.1,23.3z M5,22.4l14.1,0.1
	c0.2,0,0.4-0.1,0.5-0.3c0.1-0.1,0.1-0.3,0.1-0.5l0.1-15.2l-5.2-5.1H5.3C5,1.5,4.8,1.6,4.6,1.8C4.4,1.9,4.4,2,4.4,2.2L4.3,21.6
	c0,0.2,0,0.5,0.2,0.6C4.6,22.4,4.8,22.5,5,22.4z"/>
	<path d="M20.1,6.9H16c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.4-1.1V1.1h0.8v4.2c0,0.2,0.1,0.4,0.2,0.5C15.5,6,15.8,6.1,16,6.1
	h4.1V6.9z"/>
</g>
</svg>

<!-- ---- SUMMARY ---- -->
<svg class="icon" version="1.1" id="Layer_4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'summary'" aria-hidden="true" role="img" aria-label="summary icon">
<g id="Form">
	<path d="M16.2,15.2H9.4C9.2,15.2,9,15,9,14.8s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.4,15.2,16.2,15.2z"/>
	<path d="M16.3,12.4H9.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.5,12.4,16.3,12.4z"/>
	<path d="M16.3,17.9H9.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h6.8c0.2,0,0.4,0.2,0.4,0.4S16.5,17.9,16.3,17.9z"/>
	<circle cx="6.7" cy="12" r="0.3"/>
	<circle cx="6.7" cy="14.8" r="0.3"/>
	<circle cx="6.7" cy="17.5" r="0.3"/>
	<path d="M19.1,23.3l-14-0.1c-0.4,0.1-0.9-0.1-1.2-0.4c-0.3-0.3-0.5-0.8-0.4-1.2L3.6,2.3l0,0c0-0.4,0.1-0.8,0.4-1.1
	c0.3-0.3,0.8-0.5,1.3-0.5H15l5.6,5.5l-0.1,15.6c0,0.3-0.1,0.6-0.3,0.9C19.9,23.1,19.5,23.3,19.1,23.3z M5,22.4l14.1,0.1
	c0.2,0,0.4-0.1,0.5-0.3c0.1-0.1,0.1-0.3,0.1-0.5l0.1-15.2l-5.2-5.1H5.3C5,1.5,4.8,1.6,4.6,1.8C4.4,1.9,4.4,2,4.4,2.2L4.3,21.6
	c0,0.2,0,0.5,0.2,0.6C4.6,22.4,4.8,22.5,5,22.4z"/>
	<path d="M20.1,6.9H16c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.4-1.1V1.1h0.8v4.2c0,0.2,0.1,0.4,0.2,0.5C15.5,6,15.8,6.1,16,6.1
	h4.1V6.9z"/>
</g>
</svg>


<!-- ---- CASHBACK ---- -->
<svg class="icon" version="1.1" id="Layer_5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 23.2 23.2" style="enable-background:new 0 0 23.2 23.2;" xml:space="preserve" v-if="icon === 'cashback'" aria-hidden="true" role="img" aria-label="cashback icon">
<path d="M9.7,13.6C9.7,13.6,9.7,13.6,9.7,13.6c0.3,0,0.5-0.2,0.5-0.4c0-0.2-0.1-0.3-0.3-0.4c-1.9-0.6-3.5-2.2-4-4.2
C4.8,5.8,6.2,2.6,9,1.3c1.7-0.8,3.6-0.8,5.3,0c1.6,0.7,2.8,2.2,3.2,3.9c0.7,2.2,0.1,4.5-1.6,6.1V9.7c0-0.2-0.2-0.4-0.4-0.4
c-0.2,0-0.4,0.2-0.4,0.4v2.7l2.9-0.4c0.2,0,0.4-0.2,0.3-0.4c0,0,0,0,0,0c0-0.2-0.2-0.4-0.4-0.3c0,0,0,0,0,0l-1.2,0.1
c1.6-1.8,2.1-4.2,1.4-6.5c-0.5-1.9-1.8-3.5-3.6-4.4c-1.9-0.9-4.1-0.9-5.9,0C5.5,2.1,4,5.6,5.1,8.9C5.8,11.1,7.5,12.9,9.7,13.6z"/>
<path d="M8.6,7.7c0,0.2,0.2,0.4,0.4,0.4h0l0.7,0c0.1,0.3,0.3,0.6,0.5,0.9c0.4,0.5,1.1,0.8,1.8,0.8c0.5,0,1-0.1,1.4-0.4
c0.2-0.1,0.2-0.3,0.1-0.5S13.2,8.7,13,8.8c-0.7,0.4-1.7,0.3-2.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.4l1,0c0.2,0,0.4-0.2,0.4-0.4
c0,0,0,0,0,0c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0l-1.3,0.1c-0.1-0.2-0.1-0.5,0-0.7L12,6.5c0.2,0,0.4-0.2,0.4-0.4
c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0l-1.6,0.1c0.1-0.2,0.2-0.4,0.3-0.5c0.5-0.7,1.5-0.9,2.1-0.4c0,0,0,0,0,0C13,5,13.3,5,13.4,4.8
s0.1-0.4-0.1-0.5l0,0c-1-0.8-2.4-0.6-3.2,0.4c0,0,0,0,0,0c-0.2,0.3-0.4,0.7-0.5,1L9,5.8C8.8,5.8,8.6,6,8.6,6.2
c0,0.2,0.2,0.4,0.4,0.4h0l0.4,0c0,0.2,0,0.5,0,0.7L9,7.3C8.8,7.3,8.6,7.5,8.6,7.7z"/>
<path d="M22.5,13.1c-0.6-0.6-1.6-0.6-2.2-0.1l-0.2,0.3c-0.3-0.2-0.5-0.3-0.8-0.5c-0.5-0.2-1,0-1.4,0.3l-2.5,3c0,0,0,0,0,0L9.7,14
c-1.6-0.4-3.3-0.6-4.9-0.5c-1.7,0.2-3.3,1-4.6,2.1C0,15.8,0,16,0.1,16.2c0.1,0.1,0.4,0.2,0.5,0c1.2-1,2.6-1.7,4.2-1.9
c1.6-0.1,3.1,0.1,4.6,0.5l5.4,2.1c0.4,0.3,0.6,0.8,0.5,1.3c-0.2,0.4-0.6,0.7-1.1,0.8l-4-1.1C10,17.7,9.8,17.8,9.7,18
c-0.1,0.2,0.1,0.4,0.3,0.5l4.1,1.1l0.1,0c0.8,0,1.5-0.5,1.8-1.2c0.1-0.1,0.1-0.3,0.1-0.4l1-0.1l3.7-4.3c0.4-0.3,0.9-0.2,1.2,0.1
c0.4,0.2,0.5,0.7,0.3,1.1c0,0,0,0.1,0,0.1l-3.2,4L12,22.4l-3.6-1.5c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5l4,1.7l7.5-3.7
l3.4-4.2C23.4,14.6,23.3,13.7,22.5,13.1C22.6,13.1,22.5,13.1,22.5,13.1z M16.8,17.1L16,17.2c-0.1-0.2-0.2-0.3-0.3-0.5l2.5-3
c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.4,0.2,0.6,0.4L16.8,17.1z"/>
<path d="M6.6,20.3l-1.5-0.7l-4.8,0.1C0.2,19.6,0,19.8,0,20c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0L5,20.3l1.3,0.7c0.1,0,0.1,0,0.2,0
c0.2,0,0.4-0.2,0.4-0.4C6.9,20.5,6.8,20.3,6.6,20.3z"/>
</svg>

<!-- ---- GIFT ---- -->
<svg class="icon" version="1.1" id="Layer_6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'gift'" aria-hidden="true" role="img" aria-label="gift icon">
<path d="M19.4,22.3H3.7c-0.5,0-0.9-0.4-0.9-0.8V10.2h0.8v11.2c0,0,0,0.1,0.1,0.1h15.7c0.1,0,0.1-0.1,0.1-0.1V10.2h0.8v11.2
C20.3,21.9,19.9,22.3,19.4,22.3z"/>
<path d="M10,22l0-16.1L6.6,4.3L6.6,4.2C6.4,3.9,6,3,6.2,2.4c0.1-0.2,0.2-0.3,0.4-0.4c0.5-0.3,1-0.4,1.6-0.2c1.4,0.4,2.6,1.9,3.1,2.7
l2.8-3.7l0.3,0.1c0.6,0.3,2.7,1.4,3.1,2.2c0.1,0.1,0.1,0.3,0,0.5C17,4.5,13.6,5.6,12.2,6l-0.2-0.7c2-0.6,4.3-1.4,4.7-1.9
c-0.3-0.4-1.4-1-2.4-1.6l-3.1,4L11,5.4c0,0-1.4-2.4-3-2.9C7.6,2.5,7.2,2.5,6.9,2.7c0,0.1,0.1,0.6,0.3,1l3.6,1.8l0,16.5L10,22z"/>
<path d="M20.8,10.7H2.4V5.3h18.4V10.7z M3.1,9.9h16.9V6H3.1V9.9z"/>
<rect x="11.7" y="5.7" width="0.8" height="16.2"/>
</svg>


<!-- ---- SURVEY ---- -->
<svg class="icon" version="1.1" id="Layer_7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" v-if="icon === 'survey'" aria-hidden="true" role="img" aria-label="survey icon">
<path d="M18.4,4.3L16.7,6l-0.6-0.7c-0.1-0.2-0.4-0.2-0.5,0s-0.2,0.4,0,0.5l0.9,1c0.1,0.1,0.2,0.1,0.3,0.1h0c0.1,0,0.2,0,0.3-0.1l2-2
c0.1-0.1,0.2-0.4,0-0.5C18.8,4.1,18.6,4.1,18.4,4.3C18.4,4.3,18.4,4.3,18.4,4.3L18.4,4.3z"/>
<path d="M22.6,1.4C22.3,1.2,21.9,1,21.5,1l-8.2,0c-0.4-0.1-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.7-0.4,1.2l-0.1,5.7
c0,0.4,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.4,1.1,0.4l1.1-0.1V12c0,0.2,0.2,0.4,0.4,0.4c0.1,0,0.2,0,0.3-0.1l2.6-2.5h4
c0.4,0,0.8-0.2,1.1-0.4C22.8,9,23,8.7,23,8.3V2.5C23,2.1,22.8,1.7,22.6,1.4z M22.2,8.3c0,0.2-0.1,0.4-0.2,0.5C21.9,9,21.7,9,21.5,9
h-4.2c-0.1,0-0.2,0-0.3,0.1l-2.1,2V9.4C15,9.2,14.8,9,14.6,9l0,0l-1.5,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.2-0.3-0.4-0.3-0.6l0.1-5.8
c0-0.2,0-0.4,0.2-0.6c0.2-0.2,0.4-0.2,0.6-0.2h8.3c0.2,0,0.4,0.1,0.5,0.1c0.1,0.2,0.2,0.4,0.2,0.6V8.3z"/>
<path d="M6.6,11.1c1.6,0.1,3-1.1,3.1-2.7c0-0.1,0-0.3,0-0.4C9.6,6.4,8.2,5.1,6.6,5C5,4.9,3.6,6.1,3.5,7.7c0,0.1,0,0.2,0,0.3
c-0.1,1.6,1,2.9,2.6,3.1C6.3,11.1,6.4,11.1,6.6,11.1z M6.6,5.8c1.2,0.1,2.2,1,2.3,2.2c0.1,1.2-0.8,2.2-2,2.3c-0.1,0-0.2,0-0.3,0
c-1.2,0.1-2.2-0.7-2.3-1.9c0-0.2,0-0.3,0-0.5c-0.1-1.2,0.9-2.2,2-2.2C6.4,5.8,6.5,5.8,6.6,5.8L6.6,5.8z"/>
<path d="M12.8,17.3l0.8-2.5c0.1-0.2,0-0.4-0.2-0.5c0,0-0.1,0-0.1,0h-0.9c0-0.7-0.3-1.3-0.7-1.8c-0.6-0.5-1.3-0.8-2-0.9h-6
c-0.7,0-1.3,0.3-1.8,0.8c-0.4,0.5-0.7,1.1-0.7,1.8l0.1,3.9c0,0.6,0.2,1.1,0.5,1.5c0.2,0.2,0.4,0.4,0.7,0.5v2.4
c0,0.2,0.2,0.4,0.4,0.4h7.9c0.2,0,0.3-0.1,0.4-0.3l0.8-2.3h0.6c0,0,0.1,0,0.1,0c0.9-0.4,1.3-1.4,0.9-2.2
C13.3,17.8,13.1,17.5,12.8,17.3L12.8,17.3z M1.9,18.1l-0.1-3.9c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.8-0.6,1.2-0.6h6
c0.6,0.1,1.1,0.3,1.5,0.7c0.3,0.4,0.5,0.8,0.5,1.3H8c-0.2,0-0.3,0.1-0.4,0.3L6.8,17H4.4v-2.3c0-0.2-0.2-0.4-0.4-0.4
c-0.2,0-0.4,0.2-0.4,0.4v2.7c0,0.2,0.2,0.4,0.4,0.4h2.6L6,19.6H3.3c-0.4,0-0.8-0.2-1.1-0.5C2,18.8,1.9,18.5,1.9,18.1z M3.1,20.4
c0.1,0,0.2,0,0.2,0h2.4l-0.6,1.9H3.1L3.1,20.4z M10.3,22.2H5.9l2.4-7.1h4.4L12,17.2l-1.3-0.1c-0.1,0-0.2,0-0.3,0.1
c-0.1,0.1-0.1,0.2-0.1,0.3V20c0,0.2,0.2,0.4,0.4,0.4H11L10.3,22.2z M12.3,19.6h-1.2v-1.7l1.3,0.1c0.3,0.2,0.4,0.5,0.4,0.8
C12.8,19.1,12.6,19.5,12.3,19.6L12.3,19.6z"/>
</svg>




</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"",
		props: {
			icon: {
				type: String,
				required: true
			}
		},

	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
