<template>

  <div id="loader" v-show="$store.getters.isSpinnerSpinning">
    <div class="loader-message">
      <!-- DEFAULT LOADER --->
      <div class="loader-visual" v-if="loaderType === 'default'">&nbsp;</div>

      <!-- GRADIENT LOADER --->
      <div class="gradientSpinner" v-if="loaderType === 'gradient'">&nbsp;</div>

      <!-- POP IN DOTS LOADER --->
      <div class="popInDotsWrapper" v-if="loaderType === 'popIn'"><div class="popInDots"></div></div>

      <!-- DOT LOADER MOVING HORIZONTALLY--->
      <div class="circleWithShadows" v-if="loaderType === 'horizontalMove'"></div>

      <!-- ALT VERSION --->
      <div class="circleWithShadowsAlt" v-if="loaderType === 'horizontalMoveAlt'"></div>

      <!-- CIRCLES ROTATING LOADER --->
      <div class="circlesRotating" v-if="loaderType === 'circlesRotating'">
        <div class="circlesWrapper">
          <div class="circle0"></div>
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
          <div class="circle5"></div>
          <div class="circle6"></div>
          <div class="circle7"></div>
          <div class="circle8"></div>
          <div class="circle9"></div>
          <div class="circle10"></div>
        </div>
      </div>

      <!-- BOUNCING DOTS LOADER --->
      <div class="bouncingDots" v-if="loaderType === 'bouncingDots'">
        <div class="dot0"></div>
        <div class="dot1"></div>
        <div class="dot2"></div>
        <div class="dot3"></div>
      </div>

      <!-- COFFEE MUG LOADER --->
      <div class="coffeeLoader" v-if="loaderType === 'coffeeLoader'">
        <div class="smoke">
          <svg id="smoke1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 109.2 289" preserveAspectRatio="none" style="width:20%;">
            <path class="coffeeSmoke" d="M58, 0.8s8.4,15.5,8.1,41c0,0-25.8,23.9-14.9,54.9,0,0,4.2,14.2,21.6,33,0,0,24,26.2,26.4,58.5,0,0,3,28.4-17.3,55.6,0,0-14.9,19.7-30,29.1,0,0-7.8-9-9-32.6,0,0-1-8.1,4.5-13.2,0,0,10.3-10.7,12.3-23.6,0,0,3.2-13.9-2.3-26.2,0,0-10.3-22.9-23.3-34.9,0,0-22.3-26.8-24.1-56.2,0,0-.8-31.3,16.7-54.9,0,0,21.3-22.9,31.3-30.4Z"/>
          </svg>
          <svg id="smoke2" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 109.2 289" preserveAspectRatio="none" style="width:20%;">
            <path class="coffeeSmoke2" d="M58,7.8s8.4,15.5,8.1,41c0,0-25.8,23.9-14.9,54.9,0,0,4.2,14.2,21.6,33,0,0,24,26.2,26.4,58.5,0,0,3,28.4-17.3,55.6,0,0-14.9,19.7-30,29.1,0,0-7.8-9-9-32.6,0,0-1-8.1,4.5-13.2,0,0,10.3-10.7,12.3-23.6,0,0,3.2-13.9-2.3-26.2,0,0-10.3-22.9-23.3-34.9,0,0-22.3-26.8-24.1-56.2,0,0-.8-31.3,16.7-54.9,0,0,21.3-22.9,31.3-30.4Z"/>
          </svg>
        </div>
        <svg id="mug" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <path class="mugIcon" d="M96 64c0-17.7 14.3-32 32-32l320 0 64 0c70.7 0 128 57.3 128 128s-57.3 128-128 128l-32 0c0 53-43 96-96 96l-192 0c-53 0-96-43-96-96L96 64zM480 224l32 0c35.3 0 64-28.7 64-64s-28.7-64-64-64l-32 0 0 128zM32 416l512 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 480c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/>
        </svg>
      </div>

      <!-- DOUBLE LOADER --->
      <div class="doubleSpinner" v-if="loaderType === 'doubleSpinner'">
        <div class="outerSpinner"></div>
        <div class="innerSpinner"></div>
      </div>

      <!-- PRINTER LOADER -->
      <div class="printerLoader" v-if="loaderType === 'printer'">
        <svg id="printer" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 720 393.78">
          <path class="printerSvg" d="M666.1,0H50.95C22.81,0,0,22.81,0,50.95v278.96c0,27.84,22.33,50.45,50.05,50.92.33,5.94,5.24,10.66,11.26,10.66h144.44c6.02,0,10.92-4.71,11.26-10.64h283.04c.34,5.93,5.24,10.64,11.26,10.64h144.44c6.02,0,10.93-4.72,11.26-10.66,27.71-.49,50.03-23.09,50.03-50.92V50.95c0-28.14-22.81-50.95-50.95-50.95ZM603.53,83.31c-9.12,0-16.51-7.39-16.51-16.51s7.39-16.51,16.51-16.51,16.51,7.39,16.51,16.51-7.39,16.51-16.51,16.51ZM650.53,83.31c-9.12,0-16.51-7.39-16.51-16.51s7.39-16.51,16.51-16.51,16.51,7.39,16.51,16.51-7.39,16.51-16.51,16.51Z"/>

        </svg>
        <div class="paperTop">
          <div class="paperTopAnimated"></div>
        </div>

        <div class="paperBottom"><p>Loading...</p>
          <div class="paperBottomAnimated"><p>Loading...</p></div>
        </div>
      </div>


      <div class="loaderText">{{$t('pageTitles.wait')}}</div>
    </div>
  </div>

</template>

<script>

import Mixins from "../mixins/NonAutomatedData";

export default {
  name:"customloaders",

  mixins: [Mixins],

}
</script>

<style scoped lang="scss">

</style>