// Cookiefirst
import config from "../config";

export default {
	data() {return {
		/* ========================================================== */
		/* Put the code for this action here */
		cookieFirstKey: "00000000-0000-0000-0000-000000000000",
		/* ========================================================== */
	}},

	methods: {

		putCookieFirstScriptInPlace(){
			console.log("--- COOKIEFIRST --------------------------------------");
			console.log("Deploying COOKIEFIRST");
			let script = document.createElement('script');
			script.innerHTML = ``;
			script.setAttribute('src', `https://consent.cookiefirst.com/banner.js`);
			script.setAttribute('data-cookiefirst-key', this.cookieFirstKey);
			document.body.prepend(script);
			console.log("-----------------------------------------");
		},

		checkIfCookieKeyIsModified(){
			return this.cookieFirstKey !== "00000000-0000-0000-0000-000000000000";
		},

		displayMissingCookieKey(){
			console.log("%cMissing Cookiefirst Key", "color: #c00; font-weight: bold;");
		},
	},

	beforeMount() {
		let keyModified = this.checkIfCookieKeyIsModified();
		console.log("=== Checking CookieFirst Requirements");

		/* If I'm in production and not on PnG action */
		if(config.isWebsiteInProduction && !this.isPnGAction){
			if(keyModified){
				this.putCookieFirstScriptInPlace();
			}else{
				this.displayMissingCookieKey();
			}
		}

		/* If I'm not in production and not on PnG action */
		if(!config.isWebsiteInProduction && !this.isPnGAction){
			console.log("Action currently in acceptance");
			if(keyModified){
				console.log("Key has been registered");
			}else{
				this.displayMissingCookieKey();
			}
		}
	}
	

}
