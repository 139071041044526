<template>
	<div class="gdpr-banner" tabindex="0">

		<div class="gdpr-content">

			<div class="gdpr-langs">

				<locale-switcher role="region" :mode="modelLangButton"></locale-switcher>

			</div>

			<div class="gdpr-title">{{ $t('gdprBanner.title') }}</div>

			<div class="gdpr-texts">

				<p>{{ $t('gdprBanner.p1') }} <span class="client">{{ source.fabname }}</span>, {{ $t('gdprBanner.p2') }} <span class="client">{{ clientAddress }}</span>, {{ $t('gdprBanner.p3') }}.
				</p>
				
				<p>{{ $t('gdprBanner.p4') }} {{ $t('gdprBanner.client') }} {{ $t('gdprBanner.p5') }}. </p>

				<p>{{ $t('gdprBanner.p6') }} <span v-if="contactEmail?.length > 0"><a :href="'mailto:' + contactEmail">{{ contactEmail }}</a></span><span v-else class="client">	{{ $t('gdprBanner.client') }}, {{ contactName }}</span>.</p>
				
				<p>{{ $t('gdprBanner.p7') }}.</p>

				<button class="gdpr-button action" @click="closeGdpr">{{ $t('gdprBanner.button') }}</button>

			</div>


		</div>
	</div>
</template>

<!-- ================================================================================== -->

<!-- Probably import some elements from the STORE -->
<!-- Make the request to action-API to have it's own content ? -->
<script>

	import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
	import NonAutomatedData from "../mixins/NonAutomatedData";

	export default {
		name:"",

		props: {
			source: {
				type: Object,
				required: true
			}
		},

		components: {
			LocaleSwitcher,
		},

		mixins: [
			NonAutomatedData,
			],

		computed: {
			contactName(){
				return `${this.source.clientContact?.firstname} ${this.source.clientContact?.name}`;
			},
			contactEmail(){
				return this.source.clientContact?.email
			},
			clientAddress(){
				return `${this.source.clientAddress?.street} ${this.source.clientAddress?.number}, ${this.source.clientAddress?.postalcode} ${this.source.clientAddress?.cityname}, ${this.source.clientAddress?.country}`;
			},
		},

		methods: {

			changeLocaleTo(locale){
				this.$store.dispatch('setLocale', {locale: locale});
				this.$i18n.locale = locale;
			},

			closeGdpr(){
				this.$emit('closeGdpr')
			}


		}


	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
