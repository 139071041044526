<template>

	<div class="breadcrumbs" :class="['steps'+steps.length]" aria-label="Fil d'ariane / breadcrumb" role="navigation" tabindex="0">

		<!-- TODO utiliser un prop pour montrer/masquer ce titre -->
		<h3 v-if="showTitle" class="title">{{$t('breadcrumbSteps.title')}}</h3>

		<div class="stepswrapper">

			<div v-for="(item, index) in steps" :key="index" class="step" :class="['step' + (index + 1), {'current': index === currentStep, 'past': index < currentStep}]">

				<icons-svg :icon="item" class="iconbox"></icons-svg>

				<div class="text">
					<div class="number" :is-valid="index < currentStep">{{index + 1}}</div>
					<span class="label" :id="`labelfor-${item}`">{{$t('breadcrumbSteps.' + item)}}</span>
				</div>
			</div>

		</div>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	import IconsSvg from '@/components/IconsSvg';

	export default {
		name:"BreadCrumbs",

		components: {
			IconsSvg,
		},

		props: {
			steps: {
				type: Array,
				required: true
			},
			currentStep: {
				type: Number,
				required: true
			},
			showTitle: {
				type: Boolean,
				required: false,
			default: true
			}
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped></style>
