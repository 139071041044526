<template>
	<input name="btnOneTrust" type="button" id="btnOneTrust" class="btnOneTrust" :value="$t('onetrust.cookie')" onclick="Optanon.ToggleInfoDisplay();">
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:""
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
	
</style>
